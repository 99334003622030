<template>
    <div>
        <!-- <QRcode width="100" height="100" :content="content" :load="false"></QRcode> -->
        <div style="margin-top:20px">
            {{ $t("barcode.infoTH").format(brandConfig.display) }}
        </div>
        <img style="width:100%" id="barcode" />
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
// import Canvas from 'canvas'

export default {
    name: "QRcodes",
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            content: 'test',
            custId: '',
            brandName: '',
            storeId: '',
            lang: '',
            brand: '',
            country: ''
        };
    },
    created() {
        console.log('enasd', this.$root.$i18n.locale)
        // this.$root.$i18n.locale = this.$route.params.lang
        // this.brandName = location.hostname.split('.')[1]
        // if(location.hostname.split('.')[1]!='esteelauder'){
        //     this.$root.$i18n.locale = this.$route.params.lang+"_jomalone"
        // }
        this.custId = this.$route.params.custId
        this.storeId = this.$route.params.storeId
        this.lang = this.$route.params.lang
        this.brand = this.$route.params.brand
        this.country = this.$route.params.countryPath
        // this.brandName = location.hostname.split('.')[1]
        console.log(this.custId)
    },
    destroyed() {
        this.goBack()
    },
    mounted() {
        JsBarcode('#barcode', this.custId, {
            format: "CODE39",
            displayValue: false,
        })
        // const canvas = new Canvas();
        // JsBarcode(canvas, "Hello");
        // let params = {
        //     custId: '123', //用户id
        // };
        // this.content = JSON.stringify(params);
    },
    methods: {
        goBack() {
            this.$router.push('/' + this.country + '/' + this.brand + '/Registration/' + this.lang + '?storeId=' + this.storeId)
        },
    }
}
</script>

